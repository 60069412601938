import { createSlice, createSelector } from '@reduxjs/toolkit'
import qs from 'qs'
import _uniqBy from 'lodash/uniqBy'
import { loggedIn, loggedOut } from '../Auth/authSlice'
import removeEmpty from '../../services/helpers/removeEmpty'

const ticketsSlice = createSlice({
  name: 'tickets',
  initialState: {
    categories: [],
    tasks: [],
    messages: {},
    counterQa: 0,
  },
  reducers: {
    tasksAdded(state, { payload: { tasks } }) {
      state.tasks = tasks || []
    },
    messagesAdded(state, { payload: { queryString, messages } }) {
      state.messages[queryString] = messages
    },
    clearedTasks(state) {
      state.tasks = []
    },
    counterAdded(state, { payload: { counter } }) {
      state.counterQa = counter
    },
  },
  extraReducers: {
    [loggedIn]: (state, { payload: { user } }) => {
      state.categories = user.qa_categories
    },
    [loggedOut]: (state) => {
      state.counterQa = 0
      state.categories = []
      state.tasks = []
      state.messages = {}
    },
  },
})

const getTasks = (state) => {
  return state.tickets.tasks
}

const getMessages = (state) => (query) => {
  const cleanQuery = removeEmpty(query)
  const queryString = qs.stringify(cleanQuery)

  return state.tickets.messages[queryString]
}

const fetchTasks = (force = false) => async (dispatch, getState, { api }) => {
  if (!force) {
    const tasks = getTasks(getState())

    if (tasks.length) {
      return tasks
    }
  }

  const response = await api.tickets.tasks()
  const tasksData = response?.data?.tasks
  const counter = response?.data?.new_qa_count

  dispatch(ticketsSlice.actions.tasksAdded({ tasks: tasksData }))
  dispatch(ticketsSlice.actions.counterAdded({ counter }))

  return tasksData
}

const fetchMessages = (data, force = false) => async (
  dispatch,
  getState,
  { api },
) => {
  const cleanQuery = removeEmpty(data)
  const queryString = qs.stringify(cleanQuery)
  const cachedMessages = getMessages(getState())(data)

  if (!force) {
    if (cachedMessages) {
      return cachedMessages
    }
  }

  const response = await api.tickets.messages(data)
  const messages = cachedMessages
    ? _uniqBy([...cachedMessages, ...response.data.messages], (item) => item.id)
    : response.data.messages

  dispatch(ticketsSlice.actions.messagesAdded({ queryString, messages }))

  return messages
}

const sendMessage = (data) => async (dispatch, getState, { api }) => {
  try {
    const response = await api.tickets.sendMessage(data, true)

    const queryString = qs.stringify({ task_id: data.task_id })
    const cachedMessages =
      getMessages(getState())({ task_id: data.task_id }) || []

    const messages = [...cachedMessages, response.data.message]

    dispatch(ticketsSlice.actions.messagesAdded({ queryString, messages }))

    return response?.status === 'success'
  } catch {
    return false
  }
}

const sendTicket = (data) => async (dispatch, getState, { api }) => {
  const result = await api.tickets.sendTask(data, true)
  const isSuccess = result?.status === 'success'

  if (isSuccess) {
    dispatch(ticketsSlice.actions.clearedTasks())
  }

  return isSuccess
}

const getCategories = createSelector(
  (state) => state.tickets,
  (tickets) => {
    const { categories } = tickets
    return {
      categories,
    }
  },
)

export const { counterAdded } = ticketsSlice.actions

export default {
  sendTicket,
  getTasks,
  getMessages,
  fetchMessages,
  fetchTasks,
  sendMessage,
  getCategories,
  reducer: ticketsSlice.reducer,
}
